<template>
	<div class="page">
		<el-tabs style="height: 100%;" type="card" v-model="byType" @tab-click="typeChange">
			<el-tab-pane label="周期统计" name="byTime">
				<el-form :inline="true" style="background-color: #fff;padding-top: 20px;">
					<el-form-item label="日期查询">
						<el-date-picker class="from-item-width" size="small" unlink-panels v-model="searchFormData.dateRang" type="daterange" value-format="yyyy-MM-dd" placeholder="按评论时间">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="城市" prop="citys">
						<el-select v-model="searchFormData.city" placeholder="请选择" size="small" @change="changeCity">
							<el-option label="全部" value="10"></el-option>
							<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>					
					<el-form-item label="电站名称">
						<el-select v-model="searchFormData.group_ids" placeholder="请选择场站" size="small" multiple filterable>
							<el-option v-for="(item,k) in groupList" :key="k" :label="item.group_name" :value="item.group_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="枪口类型">
						<el-select size="small" v-model="searchFormData.qk_type" clearable placeholder="枪口类型">
							<el-option v-for="(item,index) in $deviceType" :label="item" :value="index">
							</el-option>
						</el-select>
					</el-form-item>
					<!--搜索-->
					<el-form-item>
						<el-button type="primary" @click="getList1" size="small" icon="el-icon-search" plain>查询
						</el-button>
					</el-form-item>
					<!--重置-->
					<el-form-item>
						<el-button @click="resetForm" size="small" icon="el-icon-refresh" plain>重置
						</el-button>
					</el-form-item>
				</el-form>
				<div style="background-color: #fff;padding: 10px;margin-top: 10px;margin-bottom: 10px;">
					<div style="font-weight: bold;">周期统计</div>
					<div class="boxs">
						<div class="box">
							<div class="title">枪总数</div>
							<div class="vals"><a>{{ sums.plugs }}</a>个</div>
							<hr/>
							<div class="title">订单数</div>
							<div class="vals"><a>{{ sums.order_num }}</a>笔</div>
						</div>
						<div class="box">
							<div class="title">充电量</div>
							<div class="vals"><a>{{ (Number(sums.electric) + Number(sums.electric_other)).toFixed(2) }}</a>度</div>
							<hr/>
							<v-chart :option="eleOption1" autoresize style="height:80px"></v-chart>
						</div>
						<div class="box">
							<div class="title">充电费-原价</div>
							<div class="vals"><a>{{ (Number(sums.all_ele) + Number(sums.all_service)).toFixed(2) }}</a>元</div>
							<hr/>
							<v-chart :option="eleOption2" autoresize style="height:80px"></v-chart>
						</div>
						<div class="box">
							<div class="title">充电费-折后</div>
							<div class="vals"><a>{{ sums.pay_money }}</a>元</div>
							<hr/>
							<v-chart :option="eleOption3" autoresize style="height:80px"></v-chart>
						</div>
						<div class="box">
							<div class="title">APP/小程序订单量</div>
							<div class="vals"><a>{{ Number(sums.order_app) + Number(sums.order_min) }}</a>单</div>
							<hr/>
							<v-chart :option="eleOption7" autoresize style="height:80px"></v-chart>
						</div>
						<div class="box">
							<div class="title">启动次数</div>
							<div class="vals"><a>{{ Number(sums.run_app||0) + Number(sums.run_min||0) }}</a>次</div>
							<hr/>
							<v-chart :option="eleOption9" autoresize style="height:80px"></v-chart>
						</div>
					</div>
				</div>

				<div style="display: flex;justify-content: space-between;background-color: #fff;padding: 10px;">周期充电报表 <el-button @click="exportData('tb_grouplist2')" size="small" icon="el-icon-document" plain>导出</el-button></div>
				<el-table :data="List1" id="tb_grouplist2">
					<el-table-column prop="group_name" label="场站" align="center" />
					<el-table-column prop="city" label="城市" align="center" />
					<el-table-column label="充电量(度)" align="center">
						<template slot-scope="scope">
							总电量: {{ (Number(scope.row.electric) + Number(scope.row.electric_other)).toFixed(2) }}<br/>
							叮 当: {{ scope.row.electric }}<br/>
							第三方: {{ scope.row.electric_other }}<br/>
						</template>
					</el-table-column>
					<el-table-column prop="order_num" label="充电次数" align="center" />
					<el-table-column label="充电费-原价(元)" align="center">
						<template slot-scope="scope">
							总收入: {{ (Number(scope.row.all_ele) + Number(scope.row.all_service)).toFixed(2) }}<br/>
							电 费: {{ scope.row.all_ele }}<br/>
							服务费: {{ scope.row.all_service }}<br/>
						</template>
					</el-table-column>
					<el-table-column label="充电费-折后(元)" align="center">
						<template slot-scope="scope">
							总收入: {{ scope.row.pay_money }}<br/>
							电 费: {{ scope.row.pay_ele }}<br/>
							服务费: {{ scope.row.pay_service }}<br/>
						</template>
					</el-table-column>
					<el-table-column prop="plugs" label="充电枪" align="center" />
					<el-table-column prop="plug_avg" label="枪均" align="center" />
					<el-table-column label="APP/小程序订单量" align="center">
						<template slot-scope="scope">
							APP: {{ scope.row.order_app }}<br/>
							小程序: {{ scope.row.order_min }}<br/>
						</template>
					</el-table-column>
				</el-table>				
			</el-tab-pane>
			<el-tab-pane label="累计统计" name="byGroup">
				<el-form :inline="true" style="background-color: #fff;padding-top: 20px;">
					<el-form-item label="城市" prop="citys">
						<el-select v-model="searchFormData2.city" placeholder="请选择" size="small" @change="changeCity">
							<el-option label="全部" value="10"></el-option>
							<el-option v-for="(item,k) in $userArea" :key="k" :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>					
					<el-form-item label="电站名称">
						<el-select v-model="searchFormData2.group_ids" placeholder="请选择场站" size="small" multiple>
							<el-option v-for="(item,k) in groupList" :key="k" :label="item.group_name" :value="item.group_id"></el-option>
						</el-select>
					</el-form-item>
					<!--搜索-->
					<el-form-item>
						<el-button type="primary" @click="getList2" size="small" icon="el-icon-search" plain>查询
						</el-button>
					</el-form-item>
					<!--重置-->
					<el-form-item>
						<el-button @click="resetForm" size="small" icon="el-icon-refresh" plain>重置
						</el-button>
					</el-form-item>
				</el-form>
				<div style="background-color: #fff;padding: 10px;margin-top: 10px;margin-bottom: 10px;">
					<div style="font-weight: bold;">周期统计</div>
					<div class="boxs">
						<div class="box">
							<div class="title">枪总数</div>
							<div class="vals"><a>{{ sums2.plugs }}</a>个</div>
							<hr/>
							<div class="title">订单数</div>
							<div class="vals"><a>{{ sums2.order_num }}</a>笔</div>
						</div>
						<div class="box">
							<div class="title">充电量</div>
							<div class="vals"><a>{{ (Number(sums2.electric) + Number(sums2.electric_other)).toFixed(2) }}</a>度</div>
							<hr/>
							<v-chart :option="eleOption4" autoresize style="height:80px"></v-chart>
						</div>
						<div class="box">
							<div class="title">充电费-原价</div>
							<div class="vals"><a>{{ (Number(sums2.all_ele) + Number(sums2.all_service)).toFixed(2) }}</a>元</div>
							<hr/>
							<v-chart :option="eleOption5" autoresize style="height:80px"></v-chart>
						</div>
						<div class="box">
							<div class="title">充电费-折后</div>
							<div class="vals"><a>{{ sums2.pay_money }}</a>元</div>
							<hr/>
							<v-chart :option="eleOption6" autoresize style="height:80px"></v-chart>
						</div>
						<div class="box">
							<div class="title">APP/小程序订单量</div>
							<div class="vals"><a>{{ Number(sums2.order_app) + Number(sums2.order_min) }}</a>单</div>
							<hr/>
							<v-chart :option="eleOption8" autoresize style="height:80px"></v-chart>
						</div>
						<!--div class="box">
							<div class="title">启动次数</div>
							<div class="vals"><a>{{ Number(sums2.run_app||0) + Number(sums2.run_min||0) }}</a>次</div>
							<hr/>
							<v-chart :option="eleOption10" autoresize style="height:80px"></v-chart>
						</div-->
					</div>
				</div>

				<div style="display: flex;justify-content: space-between;background-color: #fff;padding: 10px;">周期充电报表 <el-button @click="exportData('tb_grouplist')" size="small" icon="el-icon-document" plain>导出</el-button></div>
				<el-table :data="List2" id="tb_grouplist">
					<el-table-column prop="group_name" label="场站" align="center" />
					<el-table-column prop="city" label="城市" align="center" />
					<el-table-column label="充电量(度)" align="center">
						<template slot-scope="scope">
							总电量: {{ (Number(scope.row.electric) + Number(scope.row.electric_other)).toFixed(2) }}<br/>
							叮 当: {{ scope.row.electric }}<br/>
							第三方: {{ scope.row.electric_other }}<br/>
						</template>
					</el-table-column>
					<el-table-column prop="order_num" label="充电次数" align="center" />
					<el-table-column label="充电费-原价(元)" align="center">
						<template slot-scope="scope">
							总收入: {{ (Number(scope.row.all_ele) + Number(scope.row.all_service)).toFixed(2) }}<br/>
							电 费: {{ scope.row.all_ele }}<br/>
							服务费: {{ scope.row.all_service }}<br/>
						</template>
					</el-table-column>
					<el-table-column label="充电费-折后(元)" align="center">
						<template slot-scope="scope">
							总收入: {{ scope.row.pay_money }}<br/>
							电 费: {{ scope.row.pay_ele }}<br/>
							服务费: {{ scope.row.pay_service }}<br/>
						</template>
					</el-table-column>
					<el-table-column prop="plugs" label="充电枪" align="center" />
					<el-table-column prop="plug_avg" label="枪均" align="center" />
					<el-table-column label="APP/小程序订单量" align="center">
						<template slot-scope="scope">
							APP: {{ scope.row.order_app }}<br/>
							小程序: {{ scope.row.order_min }}<br/>
						</template>
					</el-table-column>
				</el-table>					
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import Vue from 'vue'
	import Tables from '@/components/tables'
	import XLSX from "xlsx"
	import VueECharts from "vue-echarts"

	export default {
		name: 'data-analysis-group',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '电站报表',
				byType:"byTime",
				isLoading: false,
				searchFormData: {city:'10'},
				searchFormData2: {city:"10"},
				List1: [],
				//汇总数据
				sums: {},
				sums2: {},
				List2: [],
				totalPage1: 0,
				totalPage2: 0,
				//场站列表
				groupList:[],
				chartOption:{
					tooltip: {
						trigger: 'item',
						formatter:'{b}'
					},
					legend: {
						orient: 'vertical',
						top: 'middle',
						data: ['直接访问', '联盟广告']
					},					
					series: [{
						type: 'pie',
						label: {
							show: false,
							position: 'center'
						},
						width:50,
						data: [
							{value: 335,name: '直接访问'},
							{value: 234,name: '联盟广告'}
						]
					}]
				},
				eleOption1:{},
				eleOption2:{},
				eleOption3:{},
				eleOption4:{},
				eleOption5:{},
				eleOption6:{},
				eleOption7:{},
				eleOption8:{},
				eleOption9:{},
				eleOption10:{},
			}
		},
		computed: {
		},
		mounted() {
			// setTimeout(()=>{
			// 	let divs = document.getElementsByClassName("el-tabs__content");
			// 	console.log(divs);
			// 	divs[1].style.flex = 1;
			// }, 200);
			//this.typeChange();
			this.clearSearch();
			this.getList1();
			this.getGroup();
		},
		activated() {
			
		},
		methods: {
			// 表格列表
			async getList1() {
				let params = {
					token: this.$store.state.user.token,
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Market.Group.report(params);
				this.sums = res.sums;
				let jstr = JSON.stringify(this.chartOption);
				this.eleOption1 = JSON.parse(jstr);
				this.eleOption1.legend.data = ["叮当 "+this.sums.electric, "第三方 "+this.sums.electric_other];
				this.eleOption1.series[0].data = [
					{value: this.sums.electric, name: "叮当 "+this.sums.electric},
					{value: this.sums.electric_other, name: "第三方 "+this.sums.electric_other}
				];
				this.eleOption2 = JSON.parse(jstr);
				this.eleOption2.legend.data = ["电费 "+this.sums.all_ele, "服务费 "+this.sums.all_service];
				this.eleOption2.series[0].data = [
					{value: this.sums.all_ele, name: "电费 "+this.sums.all_ele},
					{value: this.sums.all_service, name: "服务费 "+this.sums.all_service}
				];
				this.eleOption3 = JSON.parse(jstr);
				this.eleOption3.legend.data = ["电费 "+this.sums.pay_ele, "服务费 "+this.sums.pay_service];
				this.eleOption3.series[0].data = [
					{value: this.sums.pay_ele, name: "电费 "+this.sums.pay_ele},
					{value: this.sums.pay_service, name: "服务费 "+this.sums.pay_service}
				];
				this.eleOption7 = JSON.parse(jstr);
				this.eleOption7.legend.data = ["APP "+this.sums.order_app, "小程序 "+this.sums.order_min];
				this.eleOption7.series[0].data = [
					{value: this.sums.order_app, name: "APP "+this.sums.order_app, tooltip:{formatter:'APP订单<br/>{c}单({d}%)'}},
					{value: this.sums.order_min, name: "小程序 "+this.sums.order_min, tooltip:{formatter:'小程序订单<br/>{c}单({d}%)'}}
				];
				this.eleOption9 = JSON.parse(jstr);
				this.eleOption9.legend.data = ["APP "+(this.sums.run_app|0), "小程序 "+(this.sums.run_min|0)];
				this.eleOption9.series[0].data = [
					{value: this.sums.run_app|0, name: "APP "+(this.sums.run_app|0)},
					{value: this.sums.run_min|0, name: "小程序 "+(this.sums.run_min|0)}
				];
				this.List1 = res.list;
				for (let v of this.List1) {
					v.plug_avg = ((Number(v.electric)+Number(v.electric_other))/v.plugs/((new Date(v.edt)-new Date(v.bdt))/24/3600/1000+1|0)).toFixed(2);
				}
				this.isLoading = false;
			},
			async getList2() {
				let params = {
					token: this.$store.state.user.token,
				}
				params = Object.assign(params, this.searchFormData2);
				this.isLoading = true
				const res = await this.$api.Market.Group.report(params);
				this.sums2 = res.sums;
				let jstr = JSON.stringify(this.chartOption);
				this.eleOption4 = JSON.parse(jstr);
				this.eleOption4.legend.data = ["叮当 "+this.sums2.electric, "第三方 "+this.sums2.electric_other];
				this.eleOption4.series[0].data = [
					{value: this.sums2.electric, name: "叮当 "+this.sums2.electric},
					{value: this.sums2.electric_other, name: "第三方 "+this.sums2.electric_other}
				];
				this.eleOption5 = JSON.parse(jstr);
				this.eleOption5.legend.data = ["电费 "+this.sums2.all_ele, "服务费 "+this.sums2.all_service];
				this.eleOption5.series[0].data = [
					{value: this.sums2.all_ele, name: "电费 "+this.sums2.all_ele},
					{value: this.sums2.all_service, name: "服务费 "+this.sums2.all_service}
				];
				this.eleOption6 = JSON.parse(jstr);
				this.eleOption6.legend.data = ["电费 "+this.sums2.pay_ele, "服务费 "+this.sums2.pay_service];
				this.eleOption6.series[0].data = [
					{value: this.sums2.pay_ele, name: "电费 "+this.sums2.pay_ele},
					{value: this.sums2.pay_service, name: "服务费 "+this.sums2.pay_service}
				];
				this.eleOption8 = JSON.parse(jstr);
				this.eleOption8.legend.data = ["APP "+this.sums2.order_app, "小程序 "+this.sums2.order_min];
				this.eleOption8.series[0].data = [
					{value: this.sums2.order_app, name: "APP "+this.sums2.order_app, tooltip:{formatter:'APP订单<br/>{c}单({d}%)'}},
					{value: this.sums2.order_min, name: "小程序 "+this.sums2.order_min, tooltip:{formatter:'小程序订单<br/>{c}单({d}%)'}}
				];
				this.eleOption10 = JSON.parse(jstr);
				this.eleOption10.legend.data = ["APP "+(this.sums2.run_app|0), "小程序 "+(this.sums2.run_min|0)];
				this.eleOption10.series[0].data = [
					{value: this.sums2.run_app|0, name: "APP "+(this.sums2.run_app|0)},
					{value: this.sums2.run_min|0, name: "小程序 "+(this.sums2.run_min|0)}
				];
				this.List2 = res.list;
				for (let v of this.List2) {
					v.plug_avg = ((Number(v.electric)+Number(v.electric_other))/v.plugs/((new Date(v.edt)-new Date(v.bdt))/24/3600/1000+1|0)).toFixed(2);
				}
				this.isLoading = false;
			},
			// 重置搜索
			clearSearch() {
				if(this.byType == "byTime"){
					if(!this.searchFormData.dateRang){
						let dt1 = new Date(), dt2 = new Date();
						dt1.setDate(dt1.getDate()-31);
						dt2.setDate(dt2.getDate()-1);
						let dstr1 = dt1.getFullYear() + "-" + (dt1.getMonth()<9?'0':'') + (dt1.getMonth()+1) + "-" + (dt1.getDate()<10?'0':'') + dt1.getDate();
						let dstr2 = dt2.getFullYear() + "-" + (dt2.getMonth()<9?'0':'') + (dt2.getMonth()+1) + "-" + (dt2.getDate()<10?'0':'') + dt2.getDate();
						//this.searchFormData.dateRang = [dstr1, dstr2];
						this.$set(this.searchFormData, "dateRang", [dstr1, dstr2]);	
					}
				}else{

				}
			},
			resetForm(){
				this.clearSearch();
			},
			typeChange(e){
				//console.log("typeChange", e);
				this.clearSearch();
				if(this.byType == "byGroup"){
					if(!("plugs" in this.sums2)){
						this.getList2();
					}
				}
			},
			changeCity(e){ //修改城市
				if(e.indexOf('10') != -1) this.searchFormData.group_ids = [];
				this.getGroup();
			},
			getGroup(){ //获取场站
				let params = {
					token: this.$store.state.user.token,
					page: 1,
					size: 100,
				}
				if(this.byType == "byTime"){
					if(this.searchFormData.city != 10) params.city = this.searchFormData.city;
				}else{
					if(this.searchFormData2.city != 10) params.city = this.searchFormData2.city;
				}
				this.$api.Device.GetGroupMinList(params).then((res)=>{
					this.groupList = res;
					let gids = [];
					for(let gid of this.searchFormData.group_ids){
						for(let gv of this.groupList){
							if(gv.group_id == gid){
								gids.push(gid);
							}
						}
					}
					this.searchFormData.group_ids = gids;
				});
			},
			exportData(tbid){ //导出数据
				//var table_elt = document.getElementById(tbid);
				// Extract Data (create a workbook object from the table)
				//var workbook = XLSX.utils.table_to_book(table_elt);
				let workbook = XLSX.utils.book_new();
				let vals = [{"group_name":"电站名称","city":"城市","electric":"总电量","order_num":"充电次数","pay_money":"充电费实收","pay_ele":"基础电费实收","pay_service":"服务费实收","all_":"充电费原价","all_ele":"基础电费原价","all_service":"服务费原价","plugs":"枪总数","plug_avg":"枪均","order_app":"APP充电量","order_min":"小程序充电量"}];
				if(this.byType == "byTime"){
					for(let v of this.List1){
						vals.push({group_name:v.group_name,city:v.city,electric:(Number(v.electric)+Number(v.electric_other)).toFixed(2),order_num:v.order_num,pay_money:v.pay_money,pay_ele:v.pay_ele,pay_service:v.pay_service,all_:(Number(v.all_ele)+Number(v.all_service)).toFixed(2),all_ele:v.all_ele,all_service:v.all_service,plugs:v.plugs,plug_avg:((Number(v.electric)+Number(v.electric_other))/v.plugs/((new Date(v.edt)-new Date(v.bdt))/24/3600/1000+1|0)).toFixed(2),order_app:v.order_app,order_min:v.order_min});
					}
				}else{
					for(let v of this.List2){
						vals.push({group_name:v.group_name,city:v.city,electric:(Number(v.electric)+Number(v.electric_other)).toFixed(2),order_num:v.order_num,pay_money:v.pay_money,pay_ele:v.pay_ele,pay_service:v.pay_service,all_:(Number(v.all_ele)+Number(v.all_service)).toFixed(2),all_ele:v.all_ele,all_service:v.all_service,plugs:v.plugs,plug_avg:((Number(v.electric)+Number(v.electric_other))/v.plugs/((new Date(v.edt)-new Date(v.bdt))/24/3600/1000+1|0)).toFixed(2),order_app:v.order_app,order_min:v.order_min});
					}
				}
				let worksheet = XLSX.utils.json_to_sheet(vals, {header:["group_name"], skipHeader: true});
				XLSX.utils.book_append_sheet(workbook, worksheet);
				// Package and Release Data (`writeFile` tries to write and save an XLSB file)
				XLSX.writeFile(workbook, "out.xlsx");
			}
		}
	}
</script>

<style lang="scss" scoped>
.boxs{
	display: flex;
	justify-content: space-around;
	.box{
		background-color: #f6f7f9;
		padding: 8px;
		min-width: 200px;
		.title{
			font-size: 14px;
		}
		.vals{
			font-size: 12px;
			color: #999;
			a{
				font-size: 28px;
				font-weight: bold;
				color: #000;
			}
		}		
	}

}
</style>
